import { ChannelType } from '../enums';
import { Spend } from './Spend';

export class EditSpendRow {
    public orderNumber?: string;
    public lineNumber?: number;
    public channelType?: ChannelType;
    public lineDescription?: string;
    public vendor?: string;
    public purchaseUrl?: string;
    public payeeCentralUrl?: string;
    public company?: string;
    public location?: string;
    public costCenter?: string;
    public glAccount?: string;
    public productLine?: string;
    public channelCode?: string;
    public project?: string;
    public customEighthSegment?: string;
    public owner?: string;
    public requester?: string;
    public createDate?: string;
    public currency?: string;
    public lineAmount?: number;
    public lineQuantity?: number;
    public unitPrice?: number;
    public receivingAccess?: string;
    public receivedLineAmount?: number;
    public receivedLineQuantity?: number;
    public invoiceStatus?: string;
    public lastReceivedDate?: string;
    public channelLineAmount?: number;
    public uncommittedBalance?: number;
    public unusedQuantity?: number;
    public spends: Spend[];
    public lineType?: string;

    public errors?: string;
    public warnings?: string;

    constructor() {
        this.spends = [];
    }
}
