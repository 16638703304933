import { bold } from 'chalk';

const Verbosity = Object.freeze({
    Debug: 0,
    Info: 1,
    Warning: 2,
    Error: 3,
    Fatal: 4,
});

function getVerbosity(): number {
    const hash = new Map(Object.entries(Verbosity).map(([key, value]) => [key.toUpperCase(), value]));
    const verbosity = (process.env.VERBOSITY || '').toUpperCase();

    return hash.get(verbosity) || Verbosity.Debug;
}

export class Logger {
    private Debug = bold.gray;
    private Info = bold.cyan;
    private Fatal = bold.red;
    private Error = bold.magentaBright;
    private Warning = bold.bgKeyword('orange');

    private verbosity: number = getVerbosity();

    public info(message: string): void {
        if (this.verbosity > Verbosity.Info) {
            return;
        }

        this.log(this.Info('[INFO]: %s'), message);
    }

    public error(message: string, error?: unknown): void {
        if (this.verbosity > Verbosity.Error) {
            return;
        }

        this.log(this.Error('[ERROR]: %s'), message, error);
    }

    public warning(message: string): void {
        if (this.verbosity > Verbosity.Warning) {
            return;
        }

        this.log(this.Warning('[WARNING]: %s'), message);
    }

    public debug(message: string): void {
        if (this.verbosity > Verbosity.Debug) {
            return;
        }

        this.log(this.Debug('[DEBUG]: %s'), message);
    }

    public fatal(message: string): void {
        this.log(this.Fatal('[FATAL]: %s'), message);
    }

    private log(...args: unknown[]) {
        console.log(...args);
    }
}

export const logger = new Logger();
